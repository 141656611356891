/* ---------------------------------
config constants
--------------------------------- */

export const GOOGLE_API_KEY =
  process.env.BUILD_ENV === "production" ||
  process.env.BUILD_ENV === "acceptance"
    ? "AIzaSyApL32CkbtwelBQ_e5AzKs4lYrakzCGT7o" // prod, acceptance
    : "AIzaSyAfuPtmgklWKtgLmTC2_8idT-f7kKwaTFg"; // dev

export const API_BASE_PATH = process.env.GATSBY_API_BASE_PATH;

export const AREA_RISERVATA = process.env.GATSBY_AREA_RISERVATA;

export const AREA_CONVENZIONATA = process.env.GATSBY_AREA_CONVENZIONATA;

export const CONTI_DEALER = process.env.GATSBY_CONTI_DEALER;

export const ACCEPTED_UPLOAD_FILE_FORMATS = "image/*, application/pdf, .pdf";

/**
 * Boolean - if true, user has confirmed cookie preferences
 */
// export const COOKIES_SELECTED = "cookies-selected";

// /**
//  * Object - list of user preferences
//  */
// export const COOKIES_PREFS = "cookies-preferences";

/**
 * Shared with area riservata: don't change
 */
export const COOKIES_PREFERENCES = "acceptedCookies";
