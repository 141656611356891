import { Box, Button, Heading, Stack, Text } from "@chakra-ui/react";
import { Link as GatsbyLink } from "gatsby";
import React from "react";
import { Head } from "../../components/Head";
import Layout from "../../components/Layout";
import Section from "../../components/Section";
import { SharedHero } from "../../components/shared/SharedHero";
import { AREA_RISERVATA } from "../../constants/config";

const buttonProps = {
  borderRadius: 0,
  bg: "transparent",
  fontWeight: "normal",
  fontSize: 18,
  color: "#000",
  py: 18,
  px: "1rem",
  height: "auto",
};

const AreaRiservataPage: React.FC = () => {
  return (
    <Layout>
      <Head title="Area Riservata" />

      <SharedHero />

      <Section bg={"utils.white"}>
        <Box>
          <Stack spacing={4} direction="column">
            <Heading
              as={"h1"}
              textAlign={"center"}
              fontSize={["24px", "32px"]}
              fontWeight={"400"}
              fontFamily={"body"}
              color={"accent.01"}
            >
              Accedi all'area riservata
            </Heading>
            <Button
              {...buttonProps}
              {...{
                borderWidth: 2,
                borderStyle: "solid",
                borderColor: "accent.01",
                color: "accent.01",
              }}
              as={GatsbyLink}
              target={"_blank"}
              to={AREA_RISERVATA}
            >
              Area clienti
            </Button>
            <Button
              {...buttonProps}
              {...{
                borderWidth: 2,
                borderStyle: "solid",
                borderColor: "accent.01",
                color: "accent.01",
              }}
              as={GatsbyLink}
              to="/area-dealer"
            >
              Area dealer
            </Button>
            <Text align={"center"}>
              Hai bisogno di assistenza?{" "}
              <GatsbyLink to="/contatti">
                <Text align={"center"} as="b">
                  Contattaci
                </Text>
              </GatsbyLink>
            </Text>
          </Stack>
        </Box>
      </Section>
    </Layout>
  );
};

export default AreaRiservataPage;
